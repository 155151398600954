<template>
  <div>
    <b-container fluid>
      <div class="content-header">
        <div class="mb-2 row">
          <div class="col-sm-12">
            <h1 class="m-0 text-dark">Orders</h1>
          </div>
          <div class="col-sm-12">
            <ol class="breadcrumb white-breadcrumb">
              <b-breadcrumb-item to="/dashboard">Dashboard</b-breadcrumb-item>
              <b-breadcrumb-item active>Orders</b-breadcrumb-item>
            </ol>
          </div>
        </div>
      </div>
      <div class="content">
        <div class="row">
          <table class="table table-striped table-bordered">
            <thead>
              <tr>
                <th>#</th>
                <th>Client Name</th>
                <th>Shop</th>
                <th>Client Phone No</th>
                <th>Client Shop Location</th>
                <th>Order No</th>
                <th>Order Status</th>
                <th>Products</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <template v-if="orders.length > 0">
                <tr v-for="(order, index) in orders" :key="order.id">
                  <td>{{ index + 1 }}</td>
                  <td>
                    <router-link :to="'orders/' + order.id">
                      {{ order.client.name }}
                    </router-link>
                  </td>
                  <td>{{ order.shop.shop_name }}</td>
                  <td>{{ order.client.mobile }}</td>
                  <td>{{ order.shop.location }}</td>
                  <td>{{ order.order_no }}</td>
                  <td>{{ order.order_status }}</td>
                  <td>
                    <table class="table table-bordered">
                      <tr>
                        <th>Product Name</th>
                        <th>Quantity</th>
                      </tr>
                      <tr v-for="item in order.items" :key="item.id">
                        <td>{{ item.product.name }}</td>
                        <td>{{ item.quantity }}</td>
                      </tr>
                    </table>
                  </td>
                  <td>
                    <div class="dropdown">
                      <button
                        class="btn btn-sm btn-outline-primary dropdown-toggle"
                        type="button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="true"
                      >
                        Action
                      </button>
                      <div class="dropdown-menu">
                        <a href="#" class="dropdown-item"> View Order </a>
                        <a href="#" class="dropdown-item"> Update Status </a>
                      </div>
                    </div>
                  </td>
                </tr>
              </template>
              <template v-else>
                <tr class="text-center">
                  <td :colspan="colspanValue">
                    <font-awesome-icon
                      icon="fa-solid fa-file-pen"
                      size="lg"
                      style="font-size: 34px; color: #f24e02"
                    />
                    <p>No Orders</p>
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
      </div>
    </b-container>
  </div>
</template>
<script>
import axios from "axios";
import config from "@/config";
export default {
  created() {
    console.log("Orders created");
    this.getOrders();
  },
  name: "OrderView",
  data() {
    return {
      orders: [],
      error: null,
      showModal: false,
      colspanValue: 9,
      baseUrl: config.baseUrl,
    };
  },
  methods: {
    getOrders() {
      const accessToken = localStorage.getItem("api_token");
      const headers = {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      };
      axios
        .get(`${this.baseUrl}/orders`, { headers: headers })
        .then((response) => {
          console.log(response.data.orders);
          this.orders = response.data.orders;
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
};
</script>
<style scoped>
.posts-wrapper {
  width: 75%;
  margin: 0 auto;
}
.post {
  margin-bottom: 25px;
  text-align: left;
}
.white-breadcrumb {
  background-color: #fff;
}
.breadcrumb a {
  align-items: center;
  color: #f24e02;
  display: flex;
  justify-content: center;
  padding: 0 0.75em;
}
.btn-outline-primary {
  background-color: #f04d02;
  border-color: #f04d02;
  color: #fff;
}
.btn-outline-primary :hover {
  background-color: #f04d02;
  border-color: #f04d02;
  color: #fff;
}
a {
  color: #f04d02;
  text-decoration: none;
  background-color: transparent;
}
</style>
