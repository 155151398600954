<template>
  <div class="width-card bg-white shadow rounded p-3 border border-white">
    <div class="">
      <div class="flex">
        <img
          class="image"
          src="https://img.icons8.com/bubbles/256/administrator-male.png"
          alt="Avatar"
        />
        <p class="px-3 py-2">
          {{ task.title }}
        </p>
      </div>
      <div class="flex-footer">
        <span class="text-sm text-gray-600">{{ task.task_date }}</span>
        <div>
          <font-awesome-icon icon="fa-solid fa-bars" />
          <font-awesome-icon icon="fa-solid fa-paperclip" class="ml-2" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    task: {
      type: Object,
      default: () => ({}),
    },
  },
  name: "TaskCard",
};
</script>
<style scoped>
.flex {
  display: flex;
}
.flex-footer {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.image {
  width: 40px;
  height: 40px;
}
.width-card {
  width: 400px;
}
</style>
