<template>
  <div>
    <div class="content-wrapper">
      <div class="content-header">
        <div class="mb-2 row">
          <div class="col-sm-12">
            <h1 class="m-0 text-dark">{{ shop.shop_name }}</h1>
          </div>
          <div class="col-sm-12">
            <ol class="breadcrumb white-breadcrumb">
              <b-breadcrumb-item to="/dashboard">Dashboard</b-breadcrumb-item>
              <b-breadcrumb-item to="/clients">Clients</b-breadcrumb-item>
              <b-breadcrumb-item active
                >{{ shop.shop_name }}
              </b-breadcrumb-item>
            </ol>
          </div>
        </div>
      </div>
      <div class="content">
        <div class="row">
          <div class="col-sm-3">
            <div class="card">
              <div class="card-body text-center">
                <font-awesome-icon
                  icon="fa-solid fa-warehouse"
                  style="color: #f24e02; font-size: 34px"
                />
                <h3>{{ shop.shop_name }}</h3>
                <h5 v-if="shop.client">{{ shop.client.name }}</h5>
              </div>
            </div>
            <div class="card">
              <div class="card-body"></div>
            </div>
          </div>
          <div class="col-sm-9">
            <div class="card">
              <div class="card-body">
                <table class="table table-striped table-bordered">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Order No</th>
                      <th>Total Items</th>
                      <th>Total Items</th>
                      <th>Order Amount</th>
                      <th>Order Date</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(order, index) in shop.orders" :key="order.id">
                      <td>{{ index + 1 }}</td>
                      <td>{{ order.order_no }}</td>
                      <td>{{ order.order_no }}</td>
                      <td>{{ order.items.length }}</td>
                      <td>{{ order.total }}</td>
                      <td>{{ order.order_date }}</td>
                      <td>{{ order.order_status }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import config from "@/config";
export default {
  name: "ShopView",
  data() {
    return {
      shop: "",
      baseUrl: config.baseUrl,
    };
  },
  mounted() {
    this.getShop();
  },
  methods: {
    getShop() {
      const accessToken = localStorage.getItem("api_token");
      const headers = {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      };
      const shopId = this.$route.params.id;
      axios
        .get(`${this.baseUrl}/clients/shop/${shopId}`, {
          headers: headers,
        })
        .then((response) => {
          this.shop = response.data;
        });
    },
  },
};
</script>
<style scoped>
.profile-user-img {
  border: 3px solid #adb5bd;
  margin: 0 auto;
  padding: 3px;
  width: 100px;
  height: 100px;
  object-fit: cover;
  object-position: center;
}
.posts-wrapper {
  width: 75%;
  margin: 0 auto;
}
.post {
  margin-bottom: 25px;
  text-align: left;
}
.tab-content {
  padding: 1rem;
}
.white-breadcrumb {
  background-color: #fff;
}
.breadcrumb a {
  align-items: center;
  color: #f24e02;
  display: flex;
  justify-content: center;
  padding: 0 0.75em;
}
.btn-outline-primary {
  background-color: #f04d02;
  border-color: #f04d02;
  color: #fff;
}
.btn-outline-primary :hover {
  background-color: #f04d02;
  border-color: #f04d02;
  color: #fff;
}
.img-fluid {
  max-width: 100%;
  height: auto;
}
.img-circle {
  border-radius: 50%;
}
.profile-username {
  font-size: 21px;
  margin-top: 5px;
  font-family: "Poppins";
}
.badge-success {
  color: #ffffff;
  background-color: #28a745;
  height: 28px;
  align-content: center;
  display: grid;
}
.badge-warning {
  color: #ffffff;
  background-color: #f24e02;
  height: 28px;
  align-content: center;
  display: grid;
}
.card {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #ffffff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  margin-bottom: 1rem;
}
</style>
