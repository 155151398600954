<template>
  <canvas id="myDoughnut" width="200" height="200"></canvas>
</template>
<script>
import Chart from "chart.js/auto";
export default {
  mounted() {
    this.barChart();
  },
  methods: {
    barChart() {
      const ctx = document.getElementById("myDoughnut");
      new Chart(ctx, {
        type: "doughnut",
        data: {
          labels: ["Resolved", "Unresolved", "Pending"],
          datasets: [
            {
              label: "Resolved / Unsolved Order Issues",
              data: [12, 19, 5],
              borderWidth: 1,
              lineTension: 0.5,
              backgroundColor: ["#386641", "#ffb703", "#f24e02"],
              borderColor: ["#386641", "#ffb703", "#f24e02"],
            },
          ],
        },
        options: {
          responsive: true,
          scales: {
            y: {
              beginAtZero: true,
              display: false,
            },
          },
        },
      });
    },
  },
};
</script>
