import { render, staticRenderFns } from "./AgentsView.vue?vue&type=template&id=cba48e4e&scoped=true&"
import script from "./AgentsView.vue?vue&type=script&lang=js&"
export * from "./AgentsView.vue?vue&type=script&lang=js&"
import style0 from "./AgentsView.vue?vue&type=style&index=0&id=cba48e4e&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cba48e4e",
  null
  
)

export default component.exports