<template>
  <div>
    <div class="login-container">
      <div class="row">
        <div class="col-sm-8">
          <div class="image-section">
            <img
              src="https://www.admin.digilab.co.ke/image/slider2.png"
              alt="Login"
            />
          </div>
        </div>
        <div class="col-sm-4">
          <div class="form-section">
            <h2>Login</h2>
            <div class="card">
              <div class="card-body">
                <div class="form-group">
                  <input
                    type="text"
                    placeholder="Enter Your Email Here..."
                    v-model="email"
                    class="form-control"
                  />
                </div>
                <div class="form-group">
                  <input
                    type="password"
                    placeholder="Enter your password Here..."
                    v-model="password"
                    class="form-control"
                  />
                </div>
                <button class="btn btn-primary" @click="login">Login</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import config from "@/config";
export default {
  name: "LoginView",
  data() {
    return {
      email: "",
      password: "",
      baseUrl: config.baseUrl,
    };
  },
  methods: {
    getBaseUrl() {
      var getUrl = window.location;
      this.baseUrl =
        getUrl.protocol +
        "//" +
        getUrl.host +
        "//" +
        getUrl.pathname.split("/")[1] +
        "/";
    },
    login() {
      axios
        .post(`${this.baseUrl}/login`, {
          email: this.email,
          password: this.password,
        })
        .then((response) => {
          console.log(response);
          localStorage.setItem("api_token", response.data.data.api_token);
          localStorage.setItem(
            "first_name",
            response.data.data.user.first_name
          );
          localStorage.setItem("last_name", response.data.data.user.last_name);
          localStorage.setItem("email", response.data.data.user.email);
          localStorage.setItem("phone", response.data.data.user.phone);
          localStorage.setItem("profile", response.data.data.user.profile);
          this.$router.push({ name: "dashboard" });
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
<style scoped>
.login-container {
  display: block;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.image-section {
  display: flex;
  padding: 2rem;
  background-color: #f04d02;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 100%;
}

.form-section {
  flex: 1;
  padding: 2rem;
  background-color: #ffffff;
}

.form-group {
  margin-bottom: 1rem;
}

.btn-primary {
  width: 100%;
  background-color: #f04d02;
  border-color: #f04d02;
}
.row {
  align-items: center;
  height: 100%;
}
.col-sm-8 {
  height: 100%;
}
h2 {
  margin-bottom: 5px;
  font-size: 50px;
  color: #f04d02;
  padding-bottom: 20px;
  font-weight: 700;
}
.form-control {
  min-height: 40px;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 0;
  border-bottom-color: currentcolor;
  border-bottom-style: none;
  border-bottom-width: 0px;
  border-bottom: 1px solid #e7e7e7;
  padding: 10px 30px 10px 0;
  color: #111111;
}
</style>
