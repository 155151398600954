<template>
  <div>
    <div class="container-wrapper">
      <div class="content-header">
        <div class="container-fluid">
          <div class="mb-2 row">
            <div class="col-sm-12">
              <h1 class="m-0 text-dark">Clients</h1>
            </div>
            <div class="col-sm-12">
              <ol class="breadcrumb white-breadcrumb">
                <b-breadcrumb-item to="/dashboard">Dashboard</b-breadcrumb-item>
                <b-breadcrumb-item active>Clients</b-breadcrumb-item>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <div class="content">
        <div class="row">
          <div class="col-sm-12">
            <div class="card">
              <div class="card-body">
                <div>
                  <b-card no-body>
                    <b-tabs card>
                      <b-tab title="Verified" active>
                        <b-card-text>
                          <table class="table table-striped table-bordered">
                            <thead>
                              <tr>
                                <th>#</th>
                                <th>Client Name</th>
                                <th>Email</th>
                                <th>Client Status</th>
                                <th>Phone</th>
                                <th>No. Shops</th>
                                <th>No. Orders</th>
                              </tr>
                            </thead>
                            <tbody>
                              <template
                                v-if="
                                  clients.length > 0 && clients.is_verified == 1
                                "
                              >
                                <tr
                                  v-for="(client, index) in clients"
                                  :key="client.id"
                                >
                                  <template v-if="client.is_verified == 1">
                                    <td>{{ index + 1 }}</td>
                                    <td>
                                      <router-link :to="'clients/' + client.id">
                                        {{ client.name }}
                                      </router-link>
                                    </td>
                                    <td>
                                      {{
                                        client.email == null
                                          ? "N/A"
                                          : client.email
                                      }}
                                    </td>
                                    <td>
                                      <span
                                        v-if="client.is_verified == 0"
                                        class="badge badge-pill badge-warning"
                                      >
                                        Not Verified
                                      </span>
                                      <span
                                        v-else
                                        class="badge badge-pill badge-warning"
                                      >
                                        Verified
                                      </span>
                                    </td>
                                    <td>{{ client.mobile }}</td>
                                    <td>{{ client.shops.length }}</td>
                                    <td>{{ client.orders.length }}</td>
                                  </template>
                                </tr>
                              </template>
                              <template v-else>
                                <tr class="text-center">
                                  <td colspan="7">
                                    <font-awesome-icon
                                      icon="fa-solid fa-people-arrows"
                                      style="color: #f24e02; font-size: 34px"
                                    />
                                    <p>No Clients</p>
                                  </td>
                                </tr>
                              </template>
                            </tbody>
                          </table>
                        </b-card-text>
                      </b-tab>
                      <b-tab title="Un-Verified">
                        <b-card-text>
                          <table class="table table-striped table-bordered">
                            <thead>
                              <tr>
                                <th>#</th>
                                <th>Client Name</th>
                                <th>Email</th>
                                <th>Client Status</th>
                                <th>Phone</th>
                                <th>No. Shops</th>
                                <th>No. Orders</th>
                              </tr>
                            </thead>
                            <tbody>
                              <template v-if="clients.length > 0">
                                <tr
                                  v-for="(client, index) in clients"
                                  :key="client.id"
                                >
                                  <template v-if="client.is_verified == 0">
                                    <td>{{ index + 1 }}</td>
                                    <td>
                                      <router-link :to="'clients/' + client.id">
                                        {{ client.name }}
                                      </router-link>
                                    </td>
                                    <td>
                                      {{
                                        client.email == null
                                          ? "N/A"
                                          : client.email
                                      }}
                                    </td>
                                    <td>
                                      <span
                                        v-if="client.is_verified == 0"
                                        class="badge badge-pill badge-warning"
                                      >
                                        Not Verified
                                      </span>
                                      <span
                                        v-else
                                        class="badge badge-pill badge-warning"
                                      >
                                        Verified
                                      </span>
                                    </td>
                                    <td>{{ client.mobile }}</td>
                                    <td>{{ client.shops.length }}</td>
                                    <td>{{ client.orders.length }}</td>
                                  </template>
                                </tr>
                              </template>
                              <template v-else>
                                <tr class="text-center">
                                  <td colspan="7">
                                    <font-awesome-icon
                                      icon="fa-solid fa-people-arrows"
                                      style="color: #f24e02; font-size: 34px"
                                    />
                                    <p>No Clients</p>
                                  </td>
                                </tr>
                              </template>
                            </tbody>
                          </table>
                        </b-card-text>
                      </b-tab>
                    </b-tabs>
                  </b-card>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import config from "@/config";
export default {
  created() {
    this.getClients();
  },
  data() {
    return {
      clients: [],
      baseUrl: config.baseUrl,
    };
  },
  methods: {
    getClients() {
      const accessToken = localStorage.getItem("api_token");
      const headers = {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      };
      axios
        .get(`${this.baseUrl}/clients`, { headers: headers })
        .then((response) => {
          this.clients = response.data;
        });
    },
  },
  name: "ClientView",
};
</script>
<style scoped>
.posts-wrapper {
  width: 75%;
  margin: 0 auto;
}
.post {
  margin-bottom: 25px;
  text-align: left;
}
.white-breadcrumb {
  background-color: #fff;
}
.breadcrumb a {
  align-items: center;
  color: #f24e02;
  display: flex;
  justify-content: center;
  padding: 0 0.75em;
}
.btn-outline-primary {
  background-color: #f04d02;
  border-color: #f04d02;
  color: #fff;
}
.btn-outline-primary :hover {
  background-color: #f04d02;
  border-color: #f04d02;
  color: #fff;
}
.img-fluid {
  max-width: 100%;
  height: auto;
}
.img-circle {
  border-radius: 50%;
}
.profile-username {
  font-size: 21px;
  margin-top: 5px;
  font-family: "Poppins";
}
.badge-success {
  color: #ffffff;
  background-color: #28a745;
  height: 28px;
  align-content: center;
  display: grid;
}
.badge-warning {
  color: #ffffff;
  background-color: #f24e02;
  height: 28px;
  align-content: center;
  display: grid;
}
.card {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #ffffff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  margin-bottom: 1rem;
}
a {
  color: #f04d02;
  text-decoration: none;
  background-color: transparent;
}
</style>
