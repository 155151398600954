<template>
  <div>
    <div class="container-wrapper">
      <div class="content-header">
        <div class="container-fluid">
          <div class="mb-2 row">
            <div class="col-sm-12">
              <h1 class="m-0 text-dark">#{{ order.order_no }}</h1>
            </div>
            <div class="col-sm-12">
              <ol class="breadcrumb white-breadcrumb">
                <b-breadcrumb-item to="/dashboard">Dashboard</b-breadcrumb-item>
                <b-breadcrumb-item to="/orders">Orders</b-breadcrumb-item>
                <b-breadcrumb-item active>{{
                  order.order_no
                }}</b-breadcrumb-item>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <div class="content">
        <div class="row">
          <div class="col-sm-3">
            <div class="card">
              <div class="card-body text-center">
                <img
                  class="profile-user-img img-fluid img-circle"
                  src="https://www.admin.digilab.co.ke/image/depot.gif"
                  alt="Img"
                />
                <div v-if="order.client">
                  <h3 class="profile-username text-center">
                    Client: {{ order.client.name }}
                  </h3>
                </div>
                <span class="badge badge-pill badge-success">
                  {{ order.order_status }}
                </span>
                <div v-if="order.is_paid == 0">
                  <span class="badge badge-pill mt-2 badge-warning">
                    Not Paid
                  </span>
                </div>
                <div v-else>
                  <span class="badge badge-pill mt-2 badge-success">
                    Paid
                  </span>
                </div>
              </div>
            </div>
            <div class="card">
              <div class="card-body">
                <div class="row">
                  <div class="col-sm-4">
                    <img
                      src="https://www.admin.digilab.co.ke/image/earnings-green-bg.png"
                      alt=""
                    />
                  </div>
                  <div class="col-sm-8">
                    <div class="row">
                      <div class="col-sm-12">
                        <strong
                          class="float-right depot-text"
                          style="font-family: 'Poppins'"
                          >Orders Items</strong
                        >
                      </div>
                      <div class="col-sm-12">
                        <strong>
                          <p
                            v-if="order.items"
                            class="float-right text-family"
                            style="
                              font-size: 25px;
                              color: #f96f34;
                              font-family: 'Poppins';
                            "
                          >
                            {{ order.items.length }}
                          </p>
                        </strong>
                      </div>
                    </div>
                  </div>
                </div>
                <hr style="background-color: green; height: 1px" />
                <div class="row pt-2">
                  <div class="col-sm-4">
                    <img
                      src="https://www.admin.digilab.co.ke/image/earnings1.png"
                      alt=""
                    />
                  </div>
                  <div class="col-sm-8">
                    <div class="row">
                      <div class="col-sm-12">
                        <strong
                          class="float-right depot-text"
                          style="font-family: 'Poppins'"
                          >Order Amount</strong
                        >
                      </div>
                      <div class="col-sm-12">
                        <strong>
                          <p
                            class="float-right text-family"
                            style="
                              font-size: 25px;
                              color: #f96f34;
                              font-family: 'Poppins';
                            "
                          >
                            {{ order.total | toCurrency }}
                          </p>
                        </strong>
                      </div>
                    </div>
                  </div>
                </div>
                <hr style="background-color: green; height: 1px" />
                <div class="row pt-2">
                  <div class="col-sm-4">
                    <img
                      src="https://www.admin.digilab.co.ke/image/delivery-orange-bg.png"
                      alt=""
                    />
                  </div>
                  <div class="col-sm-8">
                    <div class="row">
                      <div class="col-sm-12">
                        <strong
                          class="float-right depot-text"
                          style="font-family: 'Poppins'"
                          >Driver</strong
                        >
                      </div>
                      <div class="col-sm-12">
                        <strong>
                          <p
                            class="float-right text-family"
                            style="color: #f96f34; font-family: 'Poppins'"
                          >
                            Assign Order To Driver
                          </p>
                        </strong>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-9">
            <div class="card card-stepper text-black">
              <div class="card-body">
                <div class="row">
                  <div class="col-sm-6">
                    <h3>Order Information</h3>
                    <div class="card">
                      <div class="card-body">
                        <p><strong>Order No:</strong> {{ order.order_no }}</p>
                        <div v-if="order.client">
                          <p>
                            <strong>Client:</strong> {{ order.client.name }}
                          </p>
                        </div>
                        <p>
                          <strong>Order Date:</strong> {{ order.order_date }}
                        </p>
                        <p v-if="order.client">
                          <strong>Shop Name:</strong>
                          {{ order.shop.shop_name }}
                        </p>
                        <p v-if="order.client">
                          <strong>Shipped To:</strong>
                          {{ order.shop.location }}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <h3>Order Items</h3>
                    <div class="card">
                      <ul class="list-group list-group-flush">
                        <li
                          class="list-group-item"
                          v-for="item in order.items"
                          :key="item.id"
                        >
                          <div
                            class="d-flex justify-content-between align-items-center"
                          >
                            <div class="d-flex">
                              <img
                                v-if="item.product"
                                :src="item.product.image"
                                alt="Item Image"
                                class="mr-3"
                                style="max-width: 100px"
                              />
                              <div>
                                <h5 class="mb-0" v-if="item.product">
                                  {{ item.product.name }}
                                </h5>
                                <p class="mb-0">
                                  Quantity: {{ item.quantity }}
                                </p>
                              </div>
                            </div>
                            <span class="text-muted" v-if="item.product"
                              >Ksh {{ item.product.price }}</span
                            >
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import config from "@/config";
export default {
  data() {
    return {
      order: "",
      baseUrl: config.baseUrl,
    };
  },
  name: "ViewOrderView",
  mounted() {
    this.getOrder();
  },
  methods: {
    getOrder() {
      const accessToken = localStorage.getItem("api_token");
      const headers = {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      };
      const orderId = this.$route.params.id;
      axios
        .get(`${this.baseUrl}/orders/${orderId}`, {
          headers: headers,
        })
        .then((response) => {
          console.log(response);
          this.order = response.data;
        });
    },
  },
};
</script>
<style scoped>
.profile-user-img {
  border: 3px solid #adb5bd;
  margin: 0 auto;
  padding: 3px;
  width: 100px;
  height: 100px;
  object-fit: cover;
  object-position: center;
}
.posts-wrapper {
  width: 75%;
  margin: 0 auto;
}
.post {
  margin-bottom: 25px;
  text-align: left;
}
.tab-content {
  padding: 1rem;
}
.white-breadcrumb {
  background-color: #fff;
}
.breadcrumb a {
  align-items: center;
  color: #f24e02;
  display: flex;
  justify-content: center;
  padding: 0 0.75em;
}
.btn-outline-primary {
  background-color: #f04d02;
  border-color: #f04d02;
  color: #fff;
}
.btn-outline-primary :hover {
  background-color: #f04d02;
  border-color: #f04d02;
  color: #fff;
}
.img-fluid {
  max-width: 100%;
  height: auto;
}
.img-circle {
  border-radius: 50%;
}
.profile-username {
  font-size: 21px;
  margin-top: 5px;
  font-family: "Poppins";
}
.badge-success {
  color: #ffffff;
  background-color: #28a745;
  height: 28px;
  align-content: center;
  display: grid;
}
.badge-warning {
  color: #ffffff;
  background-color: #f24e02;
  height: 28px;
  align-content: center;
  display: grid;
}
.card {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #ffffff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  margin-bottom: 1rem;
}
</style>
