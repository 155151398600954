<template>
  <canvas id="myChartBar" width="200" height="200"></canvas>
</template>
<script>
import Chart from "chart.js/auto";
export default {
  mounted() {
    this.barChart();
  },
  methods: {
    barChart() {
      const ctx = document.getElementById("myChartBar");
      new Chart(ctx, {
        type: "bar",
        data: {
          labels: ["Red", "Blue", "Yellow", "Green", "Purple", "Orange"],
          datasets: [
            {
              label: "# of Votes",
              data: [12, 19, 3, 5, 2, 3],
              borderWidth: 1,
            },
          ],
        },
        options: {
          scales: {
            y: {
              beginAtZero: true,
            },
          },
        },
      });
    },
  },
};
</script>
