<template>
  <div>
    <canvas id="myChartLine" width="200" height="200"></canvas>
  </div>
</template>
<script>
import Chart from "chart.js/auto";
export default {
  mounted() {
    this.lineChart();
  },
  methods: {
    lineChart() {
      const ctx = document.getElementById("myChartLine");
      new Chart(ctx, {
        type: "line",
        data: {
          labels: ["Red", "Blue", "Yellow", "Green", "Purple", "Orange"],
          datasets: [
            {
              label: "Order Issues",
              data: [12, 19, 3, 5, 2, 3],
              borderWidth: 1,
              lineTension: 0.5,
              backgroundColor: "#f24e02",
              borderColor: "#f24e02",
            },
          ],
        },
        options: {
          responsive: true,
          scales: {
            y: {
              beginAtZero: true,
            },
          },
        },
      });
    },
  },
};
</script>
