<template>
  <div>
    <div class="container-wrapper">
      <div class="content-header">
        <div class="row mb-2">
          <div class="col-sm-12">
            <h1 class="m-0 text-dark">{{ first_name }} {{ last_name }}</h1>
          </div>
          <div class="col-sm-12">
            <ol class="breadcrumb white-breadcrumb">
              <b-breadcrumb-item to="/dashboard">Dashboard</b-breadcrumb-item>
              <b-breadcrumb-item active>{{
                first_name + " " + last_name
              }}</b-breadcrumb-item>
            </ol>
          </div>
        </div>
      </div>
      <div class="content">
        <div class="row">
          <div class="col-sm-3">
            <div class="card">
              <div class="card-body text-center">
                <img
                  class="profile-user-img img-fluid img-circle"
                  :src="profile"
                  alt="Img"
                />
                <h3 class="profile-username text-center">
                  {{ first_name + " " + last_name }}
                </h3>
                <p class="text-muted" style="font-family: 'Poppins'">
                  {{ email }}
                </p>
                <p class="text-muted" style="font-family: 'Poppins'">
                  {{ phone }}
                </p>
              </div>
            </div>
          </div>
          <div class="col-sm-9">
            <div class="card">
              <div class="card-body">
                <b-card no-body>
                  <b-tabs card>
                    <b-tab title="Bio Data" active>
                      <b-card-text>
                        <div class="row">
                          <div class="col-sm-6">
                            <div class="form-group">
                              <label for="">Firstname</label>
                              <input
                                type="text"
                                class="form-control"
                                readonly
                                :value="first_name"
                              />
                            </div>
                          </div>
                          <div class="col-sm-6">
                            <div class="form-group">
                              <label for="">Lastname</label>
                              <input
                                type="text"
                                class="form-control"
                                readonly
                                :value="last_name"
                              />
                            </div>
                          </div>
                          <div class="col-sm-6">
                            <div class="form-group">
                              <label for="">Email</label>
                              <input
                                type="text"
                                class="form-control"
                                readonly
                                :value="email"
                              />
                            </div>
                          </div>
                          <div class="col-sm-6">
                            <div class="form-group">
                              <label for="">Phone</label>
                              <input
                                type="text"
                                class="form-control"
                                readonly
                                :value="phone"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="card-footer bg-white">
                          <button
                            class="btn btn-outline-primary"
                            @click="updateProfile()"
                          >
                            Update
                          </button>
                        </div>
                      </b-card-text>
                    </b-tab>
                    <b-tab title="Update Password">
                      <b-card-text> </b-card-text>
                    </b-tab>
                  </b-tabs>
                </b-card>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import config from "@/config";
export default {
  data() {
    return {
      first_name: localStorage.getItem("first_name"),
      last_name: localStorage.getItem("last_name"),
      profile: localStorage.getItem("profile"),
      email: localStorage.getItem("email"),
      phone: localStorage.getItem("phone"),
      baseUrl: config.baseUrl,
    };
  },
  methods: {
    updateProfile() {
      const accessToken = localStorage.getItem("api_token");
      const headers = {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      };
      axios
        .post(`${this.baseUrl}profile`, { headers: headers })
        .then((response) => {
          console.log(response);
        });
    },
  },
  name: "ProfileView",
};
</script>
<style scoped>
.profile-user-img {
  border: 3px solid #adb5bd;
  margin: 0 auto;
  padding: 3px;
  width: 100px;
  height: 100px;
  object-fit: cover;
  object-position: center;
}
body {
  font-family: "Poppins";
}
.posts-wrapper {
  width: 75%;
  margin: 0 auto;
}
.post {
  margin-bottom: 25px;
  text-align: left;
}
.white-breadcrumb {
  background-color: #fff;
}
.breadcrumb a {
  align-items: center;
  color: #f24e02;
  display: flex;
  justify-content: center;
  padding: 0 0.75em;
}
.btn-outline-primary {
  background-color: #f04d02;
  border-color: #f04d02;
  color: #fff;
}
.btn-outline-primary :hover {
  background-color: #f04d02;
  border-color: #f04d02;
  color: #fff;
}
a {
  color: #f04d02;
  text-decoration: none;
  background-color: transparent;
}
.img-fluid {
  max-width: 100%;
  height: auto;
}
.img-circle {
  border-radius: 50%;
}
.profile-username {
  font-size: 21px;
  margin-top: 5px;
  font-family: "Poppins";
}
.card {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #ffffff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  margin-bottom: 1rem;
}
</style>
