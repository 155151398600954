<template>
  <div>
    <div class="content-wrapper" style="font-family: 'Poppins'">
      <div class="content-header">
        <div class="mb-2 row">
          <div class="col-sm-12">
            <h5 class="m-0 text-dark">Tickets</h5>
          </div>
          <div class="col-sm-12">
            <ol class="breadcrumb white-breadcrumb">
              <b-breadcrumb-item to="/dashboard">Dashboard</b-breadcrumb-item>
              <b-breadcrumb-item active>Tickets</b-breadcrumb-item>
            </ol>
          </div>
        </div>
      </div>
      <div class="content">
        <div class="justify-content-center">
          <div class="card">
            <div class="row overflow">
              <div
                v-for="column in columns"
                :key="column.name"
                class="mr-4 column"
              >
                <div class="card-header flex-footer">
                  <span>
                    {{ column.name }}
                  </span>
                  <font-awesome-icon icon="fa-solid fa-ellipsis-h" />
                </div>
                <button
                  class="btn btn-sm btn-outline-primary m-3"
                  @click="addTask(column)"
                >
                  Add Task
                </button>
                <div v-if="column.showTextBox">
                  <textarea
                    v-model="column.task"
                    name="task"
                    class="form-control"
                  ></textarea>
                </div>
                <draggable
                  :list="column.tasks"
                  :animation="700"
                  group="tasks"
                  class="scroll"
                  @change="onDragChange(column.id)"
                  @start="onDragStart(column.id)"
                  @drag-end="onDragEnd"
                >
                  <div
                    v-for="task in column.tasks"
                    @click="openModal(task)"
                    :key="task.id"
                  >
                    <TaskCard
                      :task="task"
                      class="mt-3 cursor-move mx-3"
                    ></TaskCard>
                    <ModalView
                      v-if="showModal"
                      :task="task"
                      @close="closeModal"
                    />
                  </div>
                </draggable>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import draggable from "vuedraggable";
import TaskCard from "./TaskCard.vue";
import ModalView from "./ModalView.vue";
import axios from "axios";
import config from "@/config";
export default {
  data() {
    return {
      columns: [],
      baseUrl: config.baseUrl,
      showModal: false,
      selectedTask: null,
      showTextBox: false,
      currentColumn: null, // New variable to track the selected column
      task: "",
    };
  },
  components: {
    draggable,
    TaskCard,
    ModalView,
  },
  created() {
    this.getColumns();
  },
  methods: {
    getColumns() {
      const accessToken = localStorage.getItem("api_token");
      const headers = {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      };
      axios
        .get(`${this.baseUrl}/columns`, { headers: headers })
        .then((response) => {
          this.columns = response.data;
        })
        .catch((error) => {
          console.log(error.message);
        });
    },
    onDragChange(id) {
      console.log(id);
    },
    onDragEnd(id) {
      console.log("Drag End" + id);
    },
    onDragStart(id) {
      console.log("Drag Start" + id);
    },
    openModal(task) {
      this.selectedTask = task;
      this.showModal = true;
      console.log(this.selectedTask);
      console.log(this.showModal);
    },
    closeModal() {
      this.selectedTask = null;
      this.showModal = false;
    },
    addTask(column) {
      // column.showTextBox = !column.showTextBox;
      // column.task = "";
      if (this.currentColumn !== column) {
        this.currentColumn = column;
        this.showTextBox = true;
      }
    },
    submitModal() {},
  },
};
</script>
<style scoped>
.posts-wrapper {
  width: 75%;
  margin: 0 auto;
}
.post {
  margin-bottom: 25px;
  text-align: left;
}
.white-breadcrumb {
  background-color: #fff;
}
.breadcrumb a {
  align-items: center;
  color: #f24e02;
  display: flex;
  justify-content: center;
  padding: 0 0.75em;
}
.btn-outline-primary {
  background-color: #f04d02;
  border-color: #f04d02;
  color: #fff;
}
.btn-outline-primary :hover {
  background-color: #f04d02;
  border-color: #f04d02;
  color: #fff;
}
.img-fluid {
  max-width: 100%;
  height: auto;
}
.img-circle {
  border-radius: 50%;
}
.profile-username {
  font-size: 21px;
  margin-top: 5px;
  font-family: "Poppins";
}
.badge-success {
  color: #ffffff;
  background-color: #28a745;
  height: 28px;
  align-content: center;
  display: grid;
}
.badge-warning {
  color: #ffffff;
  background-color: #f24e02;
  height: 28px;
  align-content: center;
  display: grid;
}
.card {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #ffffff;
  background-clip: border-box;
  border: 0px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  margin-bottom: 1rem;
}
.overflow {
  overflow-x: visible;
  flex-wrap: nowrap;
}
a {
  color: #f04d02;
  text-decoration: none;
  background-color: transparent;
}
.column {
  border: 1px solid rgba(0, 0, 0, 0.125);
  width: 100%;
}
.flex-footer {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.scroll {
  /* height: 600px; */
  /*overflow-y: scroll; */
}
.cursor-move {
  cursor: pointer;
}
</style>
