<template>
  <div>
    <header>
      <b-navbar toggleable="lg" type="dark" class="header_background">
        <b-navbar-toggle target="sidebar"></b-navbar-toggle>
        <b-navbar-brand href="#">Shwari Shop</b-navbar-brand>
        <b-navbar-nav class="ml-auto">
          <div class="dropdown">
            <a
              class=""
              type="button"
              id="userDropdown"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <img :src="profile" alt="Profile Image" class="profile-img" />
            </a>
            <div
              class="dropdown-menu dropdown-menu-right"
              aria-labelledby="userDropdown"
            >
              <b-dropdown-item>{{
                first_name + " " + last_name
              }}</b-dropdown-item>
              <hr class="dropdown-divider" />
              <b-dropdown-item to="/profile">View Profile</b-dropdown-item>
              <b-dropdown-item>Logout</b-dropdown-item>
            </div>
          </div>
        </b-navbar-nav>
      </b-navbar>
    </header>
    <div class="container-fluid">
      <div class="row">
        <nav class="col-md-2 d-none d-md-block sidebar">
          <!-- Sidebar content -->
          <div class="sidebar-logo">
            <!-- <img src="../../assets/logo.png" alt="Logo" class="logo-img" /> -->
            Shwari Shop
          </div>
          <b-nav
            vertical
            class="nav-pills nav-sidebar flex-column"
            data-widget="treeview"
            role="menu"
            data-accordion="false"
          >
            <hr class="dropdown-divider" />
            <b-nav-item to="/dashboard" active>
              <img
                src="https://www.admin.digilab.co.ke/image/dashboard.png"
                alt="Dashboard"
                class="menu-icon"
              />
              Dashboard
            </b-nav-item>
            <hr class="dropdown-divider" />
            <b-nav-item to="/orders">
              <img
                src="https://www.admin.digilab.co.ke/image/orders.png"
                alt="Orders"
                class="menu-icon"
              />
              Orders
            </b-nav-item>
            <hr class="dropdown-divider" />
            <b-nav-item to="issues">
              <img
                src="https://www.admin.digilab.co.ke/image/communication.png"
                alt="Sales Agents"
                class="menu-icon"
              />
              Issues
            </b-nav-item>
            <hr class="dropdown-divider" />
            <b-nav-item to="clients">
              <img
                src="https://www.admin.digilab.co.ke/image/client.png"
                alt="Sales Agents"
                class="menu-icon"
              />
              Clients
            </b-nav-item>
            <hr class="dropdown-divider" />
            <b-nav-item to="tickets">
              <img
                src="https://www.admin.digilab.co.ke/image/admin.png"
                alt="Sales Agents"
                class="menu-icon"
              />
              Tickets
            </b-nav-item>
            <hr class="dropdown-divider" />
            <b-nav-item to="agents">
              <img
                src="https://www.admin.digilab.co.ke/image/client.png"
                alt="Sales Agents"
                class="menu-icon"
              />
              Agents
            </b-nav-item>
            <hr class="dropdown-divider" />
            <b-nav-item to="tickets">
              <img
                src="https://www.admin.digilab.co.ke/image/reports.png"
                alt="Sales Agents"
                class="menu-icon"
              />
              Reports
            </b-nav-item>
            <hr class="dropdown-divider" />
            <b-nav-item to="profile">
              <img
                src="https://www.admin.digilab.co.ke/image/logout.png"
                alt="Sales Agents"
                class="menu-icon"
              />
              Profile
            </b-nav-item>
            <hr class="dropdown-divider" />
            <b-nav-item to="tickets">
              <img
                src="https://www.admin.digilab.co.ke/image/communication.png"
                alt="Sales Agents"
                class="menu-icon"
              />
              Commnunication
            </b-nav-item>
            <hr class="dropdown-divider" />
            <b-nav-item @click="openLogoutModal">
              <img
                src="https://www.admin.digilab.co.ke/image/logout.png"
                alt="Sales Agents"
                class="menu-icon"
              />
              Logout
            </b-nav-item>
            <b-modal ref="my-modal" hide-footer title="Using Component Methods">
              <div class="d-block">
                <form>
                  <div class="form-group text-center">
                    <p>Are you sure you want to logout?</p>
                  </div>
                </form>
              </div>
              <div class="modal-footer justify-content-center">
                <b-button
                  class="mt-2"
                  variant="outline-danger"
                  @click="hideModal"
                  >Close</b-button
                >
                <b-button
                  class="mt-2"
                  variant="outline-warning"
                  @click="submitData"
                  >Submit</b-button
                >
              </div>
            </b-modal>
          </b-nav>
        </nav>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "SidebarView",
  data() {
    return {
      isOpen: true,
      profile: localStorage.getItem("profile"),
      first_name: localStorage.getItem("first_name"),
      last_name: localStorage.getItem("last_name"),
    };
  },
  methods: {
    async submitData() {
      const accessToken = localStorage.getItem("api_token");
      try {
        const response = await fetch(
          "http://127.0.0.1/customercare/public/api/logout",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${accessToken}`,
            },
            body: JSON.stringify(this.formData),
          }
        );

        if (response.ok) {
          console.log(response);
          localStorage.removeItem("api_token");
          localStorage.removeItem("email");
          localStorage.removeItem("first_name");
          localStorage.removeItem("last_name");
          localStorage.removeItem("phone");
          localStorage.removeItem("profile");
          this.$router.push({ name: "login" });
          console.log("Data submitted successfully");
        } else {
          // Handle error response
          const errorData = await response.json();
          console.log("Error:", errorData);
        }
      } catch (error) {
        // Handle network error
        console.log("Network error:", error);
      }
    },
    openLogoutModal() {
      this.$refs["my-modal"].show();
    },
    hideModal() {
      this.$refs["my-modal"].hide();
    },
  },
};
</script>

<style scoped>
.menu-icon {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}
.sidebar {
  position: fixed;
  top: 0; /* Height of the top navbar */
  bottom: 0;
  left: 0;
  z-index: 100;
  padding-top: 1rem;
  padding-bottom: 1rem;
  overflow-x: hidden;
  overflow-y: auto; /* Enable scrolling */
  background-color: #006a34;
  border-right: 1px solid #006a34;
}
.nav-link {
  color: #c6e1b1;
}
.main {
  margin-top: 56px; /* Height of the top navbar */
}
.sidebar-logo {
  text-align: center;
  padding: 1rem;
  color: #fff;
  font-size: 30px;
}

.logo-img {
  max-width: 100%;
  height: 100px;
}
.profile-img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 2px solid #006a34;
}
.header_background {
  border-bottom: 0.5px solid #dee2e6;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #c6e1b1;
  background-color: #f24e02;
}

/* //Topbar */
/* .btn-secondary:not(:disabled):not(.disabled):active,
.btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #fff;
  border-color: #fff;
}
.btn-secondary {
  color: #fff;
  background-color: #fff;
  border-color: #fff;
} */
</style>
