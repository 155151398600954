import Vue from "vue";
import Router from "vue-router";
import Login from "@/LoginView.vue";
import OrderView from "@/views/orders/OrderView.vue";
import ViewOrderView from "@/views/orders/ViewOrderView.vue";
import IssueView from "@/views/issue/IssueView.vue";
import DashboardView from "@/views/dashboard/DashboardView.vue";
import NotFoundView from "@/views/NotFoundView.vue";
import ClientView from "@/views/clients/ClientView.vue";
import ViewClientViewVue from "@/views/clients/ViewClientView.vue";
import ProfileView from "@/views/profiles/ProfileView.vue";
import TicketView from "@/views/tickets/TicketView.vue";
import AgentView from "@/views/agents/AgentsView.vue";
import ShopView from "@/views/clients/ShopView.vue";

Vue.use(Router);
const router = new Router({
  mode: "history",
  routes: [
    {
      path: "/dashboard",
      name: "dashboard",
      component: DashboardView,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/orders",
      name: "orders",
      component: OrderView,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/orders/:id",
      name: "orders/:id",
      component: ViewOrderView,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/login",
      name: "login",
      component: Login,
    },
    {
      path: "/issues",
      name: "issues",
      component: IssueView,
      meta: {
        requiresAuth: true,
      },
    },
    //Not Found 404
    {
      path: "*",
      component: NotFoundView,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/clients",
      name: "clients",
      component: ClientView,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/clients/:id",
      name: "clients/:id",
      component: ViewClientViewVue,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/shop/:id",
      name: "shop/:id",
      component: ShopView,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/profile",
      name: "profile",
      component: ProfileView,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/tickets",
      name: "tickets",
      component: TicketView,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/agents",
      name: "agents",
      component: AgentView,
      meta: {
        requiresAuth: true,
      },
    },
  ],
});

router.beforeEach((to, from, next) => {
  const api_token = localStorage.getItem("api_token");
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);

  if (requiresAuth && !api_token) {
    next("/login");
  } else {
    next();
  }
});

export default router;
