<template>
  <div>
    <div class="content-wrapper">
      <div class="content-header">
        <div class="mb-2 row">
          <div class="col-sm-12">
            <h1 class="m-0 text-dark">{{ client.name }}</h1>
          </div>
          <div class="col-sm-12">
            <ol class="breadcrumb white-breadcrumb">
              <b-breadcrumb-item to="/dashboard">Dashboard</b-breadcrumb-item>
              <b-breadcrumb-item to="/clients">Clients</b-breadcrumb-item>
              <b-breadcrumb-item active>{{ client.name }} </b-breadcrumb-item>
            </ol>
          </div>
        </div>
      </div>
      <div class="content">
        <div class="row">
          <div class="col-sm-3">
            <div class="card">
              <div class="card-body text-center">
                <img
                  class="profile-user-img img-fluid img-circle"
                  :src="client.profile"
                  alt="Img"
                />
                <h3 class="profile-username text-center">
                  {{ client.name }}
                </h3>
                <p class="text-muted" style="font-family: 'Poppins'">
                  {{ client.mobile }}
                </p>
              </div>
            </div>
          </div>
          <div class="col-sm-9">
            <div class="card">
              <div class="card-body">
                <div class="row">
                  <div class="col-sm-12">
                    <div>
                      <b-card no-body>
                        <b-tabs card>
                          <b-tab title="Orders" active>
                            <b-card-text>
                              <div class="card">
                                <div class="card-body">
                                  <span v-if="client.orders">
                                    <div v-if="client.orders.length > 0">
                                      <table
                                        class="table table-striped table-bordered"
                                      >
                                        <thead>
                                          <tr>
                                            <th>#</th>
                                            <th>Order No</th>
                                            <th>Date</th>
                                            <th>Amount</th>
                                            <th>Order Items</th>
                                            <th>Order Status</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          <tr
                                            v-for="(
                                              order, index
                                            ) in client.orders"
                                            :key="order.id"
                                          >
                                            <td>{{ index + 1 }}</td>
                                            <td>{{ order.order_no }}</td>
                                            <td>{{ order.order_date }}</td>
                                            <td>{{ order.total }}</td>
                                            <td>
                                              <table
                                                class="table table-bordered"
                                              >
                                                <tr>
                                                  <th>Product Name</th>
                                                  <th>Quantity</th>
                                                </tr>
                                                <tr
                                                  v-for="item in order.items"
                                                  :key="item.id"
                                                >
                                                  <td>
                                                    {{ item.product.name }}
                                                  </td>
                                                  <td>{{ item.quantity }}</td>
                                                </tr>
                                              </table>
                                            </td>
                                            <td>{{ order.order_status }}</td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                    <div
                                      v-else
                                      style="
                                        display: flex;
                                        justify-content: center;
                                      "
                                    >
                                      <p>No Orders Available</p>
                                    </div>
                                  </span>
                                </div>
                              </div>
                            </b-card-text>
                          </b-tab>
                          <b-tab title="Shops">
                            <b-card-text>
                              <table
                                class="table table-stripped table-bordered"
                              >
                                <thead>
                                  <tr>
                                    <th>#</th>
                                    <th>Shop Name</th>
                                    <th>Contact Person</th>
                                    <th>Contact Person Phone</th>
                                    <th>Orders</th>
                                    <th>Location</th>
                                    <th>Depot</th>
                                    <th>Action</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr
                                    v-for="shop in client.shops"
                                    :key="shop.id"
                                  >
                                    <td>{{ shop.id }}</td>
                                    <td>
                                      <router-link :to="'/shop/' + shop.id">
                                        {{ shop.shop_name }}
                                      </router-link>
                                    </td>
                                    <td>{{ shop.contact_person_name }}</td>
                                    <td>{{ shop.contact_person_phone }}</td>
                                    <td>{{ shop.orders.length }}</td>
                                    <td>{{ shop.location }}</td>
                                    <td>{{ shop.depot.name }}</td>
                                    <td>
                                      <div class="dropdown">
                                        <button
                                          class="btn btn-sm btn-outline-primary dropdown-toggle"
                                          type="button"
                                          data-toggle="dropdown"
                                          aria-haspopup="true"
                                          aria-expanded="true"
                                        >
                                          Action
                                        </button>
                                        <div class="dropdown-menu">
                                          <a href="#" class="dropdown-item">
                                            View Shop
                                          </a>
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </b-card-text>
                          </b-tab>
                        </b-tabs>
                      </b-card>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import config from "@/config";
export default {
  data() {
    return {
      client: "",
      baseUrl: config.baseUrl,
    };
  },
  mounted() {
    this.getClient();
  },
  name: "ViewClientView",
  methods: {
    getClient() {
      const accessToken = localStorage.getItem("api_token");
      const headers = {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      };
      const clientId = this.$route.params.id;
      axios
        .get(`${this.baseUrl}/clients/${clientId}`, {
          headers: headers,
        })
        .then((response) => {
          this.client = response.data;
        });
    },
  },
};
</script>
<style scoped>
.profile-user-img {
  border: 3px solid #adb5bd;
  margin: 0 auto;
  padding: 3px;
  width: 100px;
  height: 100px;
  object-fit: cover;
  object-position: center;
}
.posts-wrapper {
  width: 75%;
  margin: 0 auto;
}
.post {
  margin-bottom: 25px;
  text-align: left;
}
.tab-content {
  padding: 1rem;
}
.white-breadcrumb {
  background-color: #fff;
}
.breadcrumb a {
  align-items: center;
  color: #f24e02;
  display: flex;
  justify-content: center;
  padding: 0 0.75em;
}
.btn-outline-primary {
  background-color: #f04d02;
  border-color: #f04d02;
  color: #fff;
}
.btn-outline-primary :hover {
  background-color: #f04d02;
  border-color: #f04d02;
  color: #fff;
}
.img-fluid {
  max-width: 100%;
  height: auto;
}
.img-circle {
  border-radius: 50%;
}
.profile-username {
  font-size: 21px;
  margin-top: 5px;
  font-family: "Poppins";
}
.badge-success {
  color: #ffffff;
  background-color: #28a745;
  height: 28px;
  align-content: center;
  display: grid;
}
.badge-warning {
  color: #ffffff;
  background-color: #f24e02;
  height: 28px;
  align-content: center;
  display: grid;
}
.card {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #ffffff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  margin-bottom: 1rem;
}
</style>
