import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import "bootstrap/dist/css/bootstrap.css";
import "./assets/css/demo.css";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "bootstrap/dist/js/bootstrap.js";
import "tailwindcss/base.css";
import { library } from "@fortawesome/fontawesome-svg-core";
import Toast from "vue-toastification";
// Import the CSS or use your own!
import "vue-toastification/dist/index.css";
import {
  faAddressCard,
  faEnvelope,
  faFilePdf,
  faFilePen,
  faFilePowerpoint,
  faUsers,
  faPeopleArrows,
  faWarehouse,
  faPaperclip,
  faBars,
  faEllipsisH,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

Vue.config.productionTip = false;

library.add(
  faAddressCard,
  faEnvelope,
  faFilePdf,
  faFilePen,
  faFilePowerpoint,
  faUsers,
  faPeopleArrows,
  faWarehouse,
  faPaperclip,
  faBars,
  faEllipsisH
);
Vue.use(Toast);
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
// Vue.use(Bar);
Vue.component("font-awesome-icon", FontAwesomeIcon);
Vue.filter("toCurrency", function (value) {
  if (typeof value !== "number") {
    return value;
  }
  var formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "Ksh",
  });
  return formatter.format(value);
});
new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
