<template>
  <div id="app">
    <div v-if="loggedIn">
      <SidebarView></SidebarView>
      <main class="col-md-10 ml-sm-auto px-4">
        <div>
          <router-view :key="$router.path"></router-view>
        </div>
      </main>
    </div>
    <main v-else class="col-md-12 remove">
      <div>
        <router-view :key="$router.path"></router-view>
      </div>
    </main>
  </div>
</template>

<script>
import SidebarView from "@/views/layouts/SidebarView.vue";
export default {
  name: "App",
  components: {
    SidebarView,
  },
  computed: {
    isLoggedIn() {
      return localStorage.getItem("api_token") !== null;
    },
    loggedIn() {
      return localStorage.getItem("api_token");
    },
  },
  watch: {
    isLoggedIn(newValue) {
      if (newValue) {
        this.$router.push({ name: "dashboard" });
      } else {
        this.$router.push({ name: "login" });
      }
    },
  },
};
</script>
<style>
.remove {
  padding-left: 0 !important;
}
</style>
