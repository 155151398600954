<template>
  <div class="modal">
    <div class="modal-dialog">
      <div class="modal-content">
        <span class="close" @click="closeModal">&times;</span>
        <h2>Task Details</h2>
        <p>Task Name: {{ task.name }}</p>
        <!-- Add more details here as needed -->
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "ModalView",
  props: ["task"],
  methods: {
    closeModal() {
      this.$emit("close");
    },
  },
};
</script>
