<template>
  <div class="container">
    <div class="row">
      <div class="col-md-6 offset-md-3 text-center">
        <h1 class="display-4 mt-5 mb-4">404 Not Found</h1>
        <p class="lead mb-4">
          Sorry, the page you are looking for could not be found.
        </p>
        <router-link to="/dashboard" class="btn btn-primary"
          >Go Back to Home</router-link
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NotFoundView",
};
</script>

<style scoped>
/* Add custom styles for the 404 page here */
</style>
