<template>
  <div>
    <div class="content-wrapper" style="font-family: 'Poppins'">
      <div class="content-header">
        <div class="mb-2 row">
          <div class="col-sm-12">
            <h5 class="m-0 text-dark">
              Customer Care Dashboard -- {{ first_name + " " + last_name }}
            </h5>
          </div>
          <div class="col-sm-12">
            <b-breadcrumb class="white-breadcrumb">
              <b-breadcrumb-item to="/dashboard">Home</b-breadcrumb-item>
              <b-breadcrumb-item active>Dashboards</b-breadcrumb-item>
            </b-breadcrumb>
          </div>
        </div>
      </div>
      <div class="content">
        <div class="row">
          <div class="col-lg-3 col-sm-6 col-xs-12">
            <div
              class="info-box d-order"
              style="
                background: linear-gradient(to right, #fce8cd, #f24d02);
                border: 1px solid #f24d02;
              "
            >
              <div class="box-icons-wrapper">
                <span class="info-box-icon info-box-icon2"></span>
                <div class="d-bg-img">
                  <img
                    src="https://www.admin.digilab.co.ke/image/client-red-bg.png"
                  />
                </div>
              </div>
              <div class="info-box-content">
                <span class="info-box-text">
                  <a href="#" style="color: #fff; float: right">Order Issues</a>
                </span>
                <span class="info-box-number"> 50 </span>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-sm-6 col-xs-12">
            <div
              class="info-box d-delivery"
              style="
                background: linear-gradient(to right, #fce8cd, #ffaf00);
                border: 1px solid #ffaf00;
              "
            >
              <div class="box-icons-wrapper">
                <span class="info-box-icon info-box-icon2"></span>
                <div class="d-bg-img">
                  <img
                    src="https://www.admin.digilab.co.ke/image/delivery-orange-bg.png"
                  />
                </div>
              </div>
              <div class="info-box-content">
                <span class="info-box-text">
                  <a href="#" style="color: #fff; float: right"
                    >Assigned To Me</a
                  >
                </span>
                <span class="info-box-number"> 0 </span>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-sm-6 col-xs-12">
            <div
              class="info-box d-earnings"
              style="
                background: linear-gradient(to right, #c7e2b2, #006a34);
                border: 1px solid #006a34;
              "
            >
              <div class="box-icons-wrapper">
                <span class="info-box-icon info-box-icon2"></span>
                <div class="d-bg-img">
                  <img
                    src="https://www.admin.digilab.co.ke/image/delivery-green-bg.png"
                  />
                </div>
              </div>

              <div class="info-box-content">
                <span class="info-box-text"
                  ><a href="#" style="color: #fff; float: right">Resolved</a>
                </span>
                <span class="info-box-number">0 </span>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-sm-6 col-xs-12">
            <div
              class="info-box d-customer"
              style="
                background: linear-gradient(to right, #fce8cd, #f24d02);
                border: 1px solid #f24d02;
              "
            >
              <div class="box-icons-wrapper">
                <span class="info-box-icon info-box-icon2"></span>
                <div class="d-bg-img">
                  <img
                    src="https://www.admin.digilab.co.ke/image/client-red-bg.png"
                  />
                </div>
              </div>
              <div class="info-box-content">
                <span class="info-box-text">
                  <a href="#" style="color: #fff; float: right">Pending</a>
                </span>
                <span class="info-box-number"> 9 </span>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-sm-4">
            <div class="card">
              <div class="card-body">
                <BarChartVue />
              </div>
            </div>
          </div>
          <div class="col-sm-4">
            <div class="card">
              <div class="card-body">
                <LineChartVue />
              </div>
            </div>
          </div>
          <div class="col-sm-4">
            <div class="card">
              <div class="card-body">
                <PieChartVue />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BarChartVue from "../charts/BarChart.vue";
import LineChartVue from "../charts/LineChart.vue";
import PieChartVue from "../charts/PieChart.vue";
export default {
  data() {
    return {
      first_name: localStorage.getItem("first_name"),
      last_name: localStorage.getItem("last_name"),
    };
  },
  methods: {},
  name: "DashboardView",
  mounted() {
    // this.renderChart();
  },
  components: { BarChartVue, LineChartVue, PieChartVue },
};
</script>
<style scoped>
.info-box-text {
  font-weight: bold;
}
.info-box .info-box-icon {
  border-radius: 0.25rem;
  -ms-flex-align: center;
  align-items: center;
  display: -ms-flexbox;
  display: flex;
  font-size: 1.875rem;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
  width: 65px;
  margin-left: 10px;
}
.info-box .info-box-content {
  -ms-flex: 1;
  flex: 1;
  padding: 5px 10px;
}
.info-box .progress-description,
.info-box .info-box-text {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.info-box .info-box-number {
  display: block;
  font-weight: 700;
}
.info-box-number {
  text-align: right;
  font-family: "Poppins";
  font-size: 25px;
  color: #fff;
}
.info-box {
  /* -webkit-box-shadow: 0 1rem 1rem rgba(0, 0, 0, 0.075) !important; */
  /* box-shadow: 0 1rem 1rem rgba(0, 0, 0, 0.075) !important; */
  border-radius: 0.25rem;
  /* background: #ffffff; */
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 1rem;
  min-height: 80px;
  padding: 0.5rem;
  position: relative;
}
.white-breadcrumb {
  background-color: #fff;
}
.breadcrumb a {
  align-items: center;
  color: #f24e02;
  display: flex;
  justify-content: center;
  padding: 0 0.75em;
}
.card {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #ffffff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  margin-bottom: 1rem;
}
</style>
