<template>
  <div>
    <b-container fluid>
      <div class="row">
        <div class="col-sm-12">
          <b-breadcrumb class="white-breadcrumb">
            <b-breadcrumb-item to="/dashboard">Dashboard</b-breadcrumb-item>
            <b-breadcrumb-item active>Orders Issues</b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <div class="card">
            <div class="card-body">
              <div>
                <b-card no-body>
                  <b-tabs card>
                    <b-tab title="Order Issues" active>
                      <b-card-text>
                        <table class="table table-striped table-bordered mt-2">
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>Order No</th>
                              <th>Client Name</th>
                              <th>Shop</th>
                              <th>Order Issue</th>
                              <th>Issue Status</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            <template v-if="orderIssues.length">
                              <tr
                                v-for="(issue, index) in orderIssues"
                                :key="issue.id"
                              >
                                <td>{{ index + 1 }}</td>
                                <td v-if="issue.order">
                                  {{ issue.order.order_no }}
                                </td>
                                <td v-if="issue.order">
                                  {{ issue.order.client.name }}
                                </td>
                                <td>
                                  {{ issue.order.shop.shop_name }}
                                </td>
                                <td v-if="issue.issue">
                                  {{ issue.issue.name }}
                                </td>
                                <td>
                                  <span
                                    v-if="issue.is_resolved == 0"
                                    class="badge badge-warning badge-pill text-white"
                                  >
                                    Not Resolved
                                  </span>
                                  <span
                                    v-else
                                    class="badge badge-success badge-pill"
                                    >Resolved
                                  </span>
                                </td>
                                <td>
                                  <div class="dropdown">
                                    <button
                                      class="btn btn-sm btn-outline-primary dropdown-toggle"
                                      type="button"
                                      data-toggle="dropdown"
                                      aria-haspopup="true"
                                      aria-expanded="true"
                                    >
                                      Action
                                    </button>
                                    <div class="dropdown-menu">
                                      <a
                                        href="#"
                                        class="dropdown-item"
                                        @click="taskModal(issue)"
                                      >
                                        Add as a Task
                                      </a>
                                    </div>
                                    <b-modal
                                      ref="my-modal"
                                      hide-footer
                                      v-model="modalVisible"
                                      title="Add Issue to Tasks"
                                    >
                                      <div class="d-block">
                                        <form>
                                          <div class="form-group">
                                            <label for="">Task Name</label>
                                            <input
                                              type="text"
                                              class="form-control"
                                              readonly
                                              v-model="order_issue_name"
                                            />
                                          </div>
                                        </form>
                                      </div>
                                      <div
                                        class="modal-footer justify-content-center"
                                      >
                                        <b-button
                                          class="mt-2 btn-sm"
                                          variant="outline-danger"
                                          @click="closeTaskModal"
                                          >Close</b-button
                                        >
                                        <b-button
                                          class="mt-2 btn-sm"
                                          variant="outline-primary"
                                          @click="submitModal"
                                          >Submit</b-button
                                        >
                                      </div>
                                    </b-modal>
                                  </div>
                                </td>
                              </tr>
                            </template>
                            <template v-else>
                              <tr class="text-center">
                                <td colspan="7">
                                  <font-awesome-icon
                                    icon="fa-solid fa-file-pdf"
                                    style="color: #f24e02; font-size: 34px"
                                  />
                                </td>
                              </tr>
                            </template>
                          </tbody>
                        </table>
                      </b-card-text>
                    </b-tab>
                    <b-tab title="Issues">
                      <b-card-text>
                        <button
                          class="btn btn-outline-primary mb-3"
                          @click="openModal"
                        >
                          Order Issue
                        </button>
                        <table class="table table-striped table-bordered">
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>Issue Name</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            <template v-if="issues.length">
                              <tr
                                v-for="(issue, index) in issues"
                                :key="issue.id"
                              >
                                <td>{{ index + 1 }}</td>
                                <td>{{ issue.name }}</td>
                                <td>
                                  <div class="dropdown">
                                    <button
                                      class="btn btn-sm btn-outline-primary dropdown-toggle"
                                      type="button"
                                      data-toggle="dropdown"
                                      aria-haspopup="true"
                                      aria-expanded="true"
                                    >
                                      Action
                                    </button>
                                    <div class="dropdown-menu">
                                      <a
                                        href="#"
                                        class="dropdown-item"
                                        @click="updateIssue(issue)"
                                      >
                                        Update
                                      </a>
                                      <a href="#" class="dropdown-item">
                                        Delete
                                      </a>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            </template>
                            <template v-else>
                              <tr class="text-center">
                                <td colspan="3">
                                  <font-awesome-icon
                                    icon="fa-solid fa-bars"
                                    size="lg"
                                    style="font-size: 34px; color: #f24e02"
                                  />
                                  <p>No Orders</p>
                                </td>
                              </tr>
                            </template>
                          </tbody>
                        </table>
                        <b-modal
                          ref="my-issue"
                          hide-footer
                          title="Using Component Methods"
                        >
                          <div class="d-block">
                            <form>
                              <div class="form-group">
                                <label for="">Issue Name</label>
                                <input
                                  type="text"
                                  class="form-control"
                                  v-model="issue_name"
                                />
                              </div>
                            </form>
                          </div>
                          <div class="modal-footer justify-content-center">
                            <b-button
                              class="mt-2 btn-sm"
                              variant="outline-danger"
                              @click="hideModal"
                              >Close</b-button
                            >
                            <b-button
                              class="mt-2 btn-sm"
                              variant="outline-primary"
                              @click="submitModal"
                              >Submit</b-button
                            >
                          </div>
                        </b-modal>
                      </b-card-text>
                    </b-tab>
                  </b-tabs>
                </b-card>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-container>
  </div>
</template>
<script>
import axios from "axios";
import config from "@/config";
export default {
  data() {
    return {
      issues: "",
      orderIssues: "",
      issue_name: "",
      order_issue_name: "",
      baseUrl: config.baseUrl,
      modalVisible: false,
    };
  },
  created() {
    this.getIssues();
  },
  mounted() {
    this.getOrderIssues();
  },
  methods: {
    getIssues() {
      const accessToken = localStorage.getItem("api_token");
      const headers = {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      };
      axios
        .get(`${this.baseUrl}/issues`, { headers: headers })
        .then((response) => {
          this.issues = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    openModal() {
      this.$refs["my-issue"].show();
    },
    taskModal(issue) {
      console.log(issue.order.shop.shop_name);
      this.modalVisible = true;
      this.order_issue_name = issue.issue.name;
    },
    closeTaskModal() {
      this.modalVisible = false;
    },
    hideModal() {
      this.$refs["my-issue"].hide();
    },
    submitModal() {
      const issueBody = {
        name: this.issue_name,
      };
      const accessToken = localStorage.getItem("api_token");
      const headers = {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      };
      axios
        .post(`${this.baseUrl}/issues/store`, issueBody, {
          headers: headers,
        })
        .then((response) => {
          if (response.data.failes) {
            Object.values(response.data.failes).forEach((value) => {
              this.$toast.error(value);
            });
          } else {
            this.$toast.success(response.data.message);
            this.hideModal();
            this.getIssues();
            this.issue_name = "";
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getOrderIssues() {
      const accessToken = localStorage.getItem("api_token");
      const headers = {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      };
      axios
        .get(`${this.baseUrl}/issues/orderIssues`, { headers: headers })
        .then((response) => {
          this.orderIssues = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    updateIssue(issue) {
      console.log(issue);
    },
  },
  name: "IssueView",
};
</script>
<style scoped>
.white-breadcrumb {
  background-color: #fff;
}
.breadcrumb a {
  align-items: center;
  color: #f24e02;
  display: flex;
  justify-content: center;
  padding: 0 0.75em;
}
.btn-outline-primary {
  border-color: #f24e02;
  color: #f24e02;
}
.btn-outline-primary:hover {
  background-color: #f24e02;
  color: #fff;
  border-color: #f24e02;
}
</style>
