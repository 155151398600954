<template>
  <div>
    <div class="container-wrapper">
      <div class="content-header">
        <div class="container-fluid">
          <div class="mb-2 row">
            <div class="col-sm-12">
              <h1 class="m-0 text-dark">Agents</h1>
            </div>
            <div class="col-sm-12">
              <ol class="breadcrumb white-breadcrumb">
                <b-breadcrumb-item to="/dashboard">Dashboard</b-breadcrumb-item>
                <b-breadcrumb-item active>Agents</b-breadcrumb-item>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <div class="content">
        <div class="row">
          <div class="col-sm-12">
            <table class="table table-striped table-bordered">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Phone</th>
                  <th>Depot</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <template v-if="agents.length > 0">
                  <tr v-for="(agent, index) in agents" :key="agent.id">
                    <td>{{ index + 1 }}</td>
                    <td>{{ agent.first_name + " " + agent.last_name }}</td>
                    <td>{{ agent.email }}</td>
                    <td>{{ agent.phone }}</td>
                    <td>{{ agent.depot.name }}</td>
                    <td>
                      <div class="dropdown">
                        <button
                          class="btn btn-sm btn-outline-primary dropdown-toggle"
                          type="button"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="true"
                        >
                          Action
                        </button>
                        <div class="dropdown-menu">
                          <a href="#" class="dropdown-item"> View Agent </a>
                        </div>
                      </div>
                    </td>
                  </tr>
                </template>
                <template v-else>
                  <tr class="text-center">
                    <td colspan="6">
                      <font-awesome-icon
                        icon="fa-solid fa-users"
                        style="color: #f24e02; font-size: 34px"
                      />
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import config from "/src/config";
export default {
  name: "AgentView",
  data() {
    return {
      agents: "",
      baseUrl: config.baseUrl,
    };
  },
  mounted() {
    this.getAgents();
  },
  methods: {
    getAgents() {
      const accessToken = localStorage.getItem("api_token");
      const headers = {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      };
      axios
        .get(`${this.baseUrl}/agents`, { headers: headers })
        .then((response) => {
          this.agents = response.data;
        });
    },
  },
};
</script>
<style scoped>
.posts-wrapper {
  width: 75%;
  margin: 0 auto;
}
.post {
  margin-bottom: 25px;
  text-align: left;
}
.white-breadcrumb {
  background-color: #fff;
}
.breadcrumb a {
  align-items: center;
  color: #f24e02;
  display: flex;
  justify-content: center;
  padding: 0 0.75em;
}
.btn-outline-primary {
  background-color: #f04d02;
  border-color: #f04d02;
  color: #fff;
}
.btn-outline-primary :hover {
  background-color: #f04d02;
  border-color: #f04d02;
  color: #fff;
}
.img-fluid {
  max-width: 100%;
  height: auto;
}
.img-circle {
  border-radius: 50%;
}
.profile-username {
  font-size: 21px;
  margin-top: 5px;
  font-family: "Poppins";
}
.badge-success {
  color: #ffffff;
  background-color: #28a745;
  height: 28px;
  align-content: center;
  display: grid;
}
.badge-warning {
  color: #ffffff;
  background-color: #f24e02;
  height: 28px;
  align-content: center;
  display: grid;
}
.card {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #ffffff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  margin-bottom: 1rem;
}
a {
  color: #f04d02;
  text-decoration: none;
  background-color: transparent;
}
</style>
